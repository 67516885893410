import { useFooterData } from '@/fetcher/useFooterData';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import LocaleLink from '../common/LocaleLink';

const Footer = () => {
  const router = useRouter();
  const { lang } = router.query;
  const { footerData, isLoading, isError } = useFooterData(
    (lang as string) || 'en',
  );

  const koreaOffice = footerData?.[0] ?? {
    section: 'Korea Headquarters',
    phone: '+82-2-6952-9370',
    email: 'info@fadu.io',
    address: '7F, 5, Teheran-ro 14-gil, Gangnam-gu, Seoul, Republic of Korea',
  };
  const siliconValleyOffice = footerData?.[1] ?? {
    section: 'Silicon Valley Office',
    phone: '669-230-4926',
    email: 'sales@fadu.io',
    address: '2712 Augustine Drive, Suite 220, Santa Clara, CA 95054',
  };

  const formatAddress = (address: string) => {
    // 주소에 이미 <br> 태그가 포함되어 있는 경우 그대로 반환
    if (address.includes('<br>')) {
      return address.split('<br>').map((line, index) => (
        <span key={index}>
          {line}
          {index < address.split('<br>').length - 1 && <br />}
        </span>
      ));
    }

    // 주소에 쉼표가 있는 경우 쉼표 기준으로 줄바꿈
    if (address.includes(',')) {
      return address.split(',').map((line, index) => (
        <span key={index}>
          {line.trim()}
          {index < address.split(',').length - 1 && <br />}
        </span>
      ));
    }

    // 그 외의 경우 단어 단위로 줄바꿈 (실리콘밸리 주소용)
    if (address.includes(' ')) {
      // 단어 단위로 분리
      const words = address.split(' ');
      let lines = [];
      let currentLine = '';

      // 단어를 조합하여 적절한 길이의 줄 생성
      for (let i = 0; i < words.length; i++) {
        if (currentLine.length + words[i].length > 20) {
          // 20자 정도에서 줄바꿈
          lines.push(currentLine.trim());
          currentLine = words[i];
        } else {
          currentLine += (currentLine ? ' ' : '') + words[i];
        }
      }

      if (currentLine) {
        lines.push(currentLine.trim());
      }

      return lines.map((line, index) => (
        <span key={index}>
          {line}
          {index < lines.length - 1 && <br />}
        </span>
      ));
    }

    // 그 외의 경우 그대로 반환
    return address;
  };

  // 실리콘밸리 주소 강제 줄바꿈 처리
  const formatSiliconValleyAddress = (address: string) => {
    // 특정 패턴으로 줄바꿈
    if (address.includes('Augustine')) {
      return (
        <>
          2712 Augustine Drive
          <br />
          Suite 220
          <br />
          Santa Clara, CA 95054
        </>
      );
    }
    return formatAddress(address);
  };

  return (
    <footer className="min-h-[403px] py-10 md:py-[69px] bg-[#bc263d]">
      <div className="flex flex-col items-center gap-5 md:gap-10 px-5 md:px-4">
        {/* Mobile logo - visible on mobile only */}
        <div className="w-full flex justify-start md:hidden">
          <img
            src="/images/footer/footer-logo.svg"
            alt="FADU Logo"
            width={220}
            height={43}
            className="object-contain"
          />
        </div>

        {/* Main content container */}
        <div className="w-full max-w-[940px] flex flex-col md:flex-row justify-between items-start">
          {/* Offices information */}
          <div className="w-full flex flex-col md:flex-row justify-start items-start gap-8 md:gap-5">
            {/* Korea Office */}
            <div className="w-full md:w-[221px]">
              <div className="text-white text-base font-bold font-['Open Sans'] leading-[23px] mb-[10px] md:mb-[20px] ml-[17px] md:ml-[20px]">
                {koreaOffice.section}
              </div>
              <div className="text-white text-sm font-normal font-['Open Sans'] leading-snug">
                <div className="flex items-center mb-2">
                  <img
                    src="/images/main/call.svg"
                    alt="Call"
                    className="w-[12px] h-[12px] md:w-[16px] md:h-[16px] mr-2"
                  />
                  {koreaOffice.phone}
                </div>
                <div className="flex items-center mb-2">
                  <img
                    src="/images/main/mail.svg"
                    alt="Email"
                    className="w-[12px] h-[12px] md:w-[16px] md:h-[16px] mr-2"
                  />
                  {koreaOffice.email}
                </div>
                <div className="flex mb-2">
                  <img
                    src="/images/main/location_on.svg"
                    alt="Location"
                    className="w-[12px] h-[12px] md:w-[16px] md:h-[16px] mr-2 mt-1 flex-shrink-0"
                  />
                  <div>{formatAddress(koreaOffice.address ?? '')}</div>
                </div>
              </div>
            </div>

            {/* Silicon Valley Office */}
            <div className="w-full md:w-[460px]">
              <div className="text-white text-base font-bold font-['Open Sans'] leading-[23px] mb-[10px] md:mb-[20px] ml-[17px] md:ml-[20px]">
                {siliconValleyOffice.section}
              </div>
              <div className="text-white text-sm font-normal font-['Open Sans'] leading-snug">
                <div className="flex items-center mb-2">
                  <img
                    src="/images/main/call.svg"
                    alt="Call"
                    className="w-[12px] h-[12px] md:w-[16px] md:h-[16px] mr-2"
                  />
                  {siliconValleyOffice.phone}
                </div>
                <div className="flex items-center mb-2">
                  <img
                    src="/images/main/mail.svg"
                    alt="Email"
                    className="w-[12px] h-[12px] md:w-[16px] md:h-[16px] mr-2"
                  />
                  {siliconValleyOffice.email}
                </div>
                <div className="flex mb-2">
                  <img
                    src="/images/main/location_on.svg"
                    alt="Location"
                    className="w-[12px] h-[12px] md:w-[16px] md:h-[16px] mr-2 mt-1 flex-shrink-0"
                  />
                  <div className="max-w-[221px]">
                    {formatSiliconValleyAddress(
                      siliconValleyOffice.address ?? '',
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Desktop logo - hidden on mobile */}
          <div className="hidden md:block">
            <img
              src="/images/footer/footer-logo.svg"
              alt="FADU Logo"
              className="object-contain"
            />
          </div>
        </div>

        {/* Copyright section */}
        <div className="w-full max-w-[940px] mt-[40px] md:mt-0">
          <div className="text-white text-[13px] sm:text-sm font-normal font-['Open Sans'] leading-snug">
            © 2015-2023 FADU Technology. All Rights Reserved.
            <br />
            FADU, the FADU Bolt and the FADU
            <br />
            logo are properties of FADU Technology.
            <br /> All other trademarks are the property of their respective
            owners.
          </div>
        </div>

        {/* Social Media Links - mobile only */}
        <div className="w-full max-w-[940px] h-[25px] flex gap-[15px] md:hidden">
          <a
            href="https://www.linkedin.com/company/fadutec/"
            target="_blank"
            rel="noopener noreferrer"
            className="w-[25px] h-[25px] bg-white rounded-[20px] justify-center gap-2.5 flex"
          >
            <img
              src="/images/main/linkedin_mo.svg"
              alt="LinkedIn"
              width={11}
              height={12}
            />
          </a>
          <a
            href="https://www.youtube.com/@fadutechnology2512"
            target="_blank"
            rel="noopener noreferrer"
            className="w-[25px] h-[25px] bg-white rounded-[20px] flex-col justify-center items-center gap-2.5 inline-flex"
          >
            <img
              src="/images/main/youtube_mo.svg"
              alt="Youtube"
              width={13}
              height={9}
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
