import Header from '@/components/Header/Header';
import Footer from '@/components/Footer/Footer';
import SiteMap from '@/components/Footer/SiteMap';
import FloatingButtons from '@/components/FloatingButtons';
import Popup from '@/components/Home/Popup';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => (
  <div className="relative w-screen min-h-screen flex flex-col overflow-x-hidden">
    <Header />
    <main className="w-full h-auto">{children}</main>
    <div className="w-full">
      <SiteMap />
      <Footer />
    </div>
    <FloatingButtons />
    <Popup />
  </div>
);

export default MainLayout;
