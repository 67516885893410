import useSWR from 'swr';
import { APIClient } from '../api/client';
import { GetFooterRes, FooterInfo } from '@/api/footer/types';

// API 응답 구조를 확인하기 위한 로깅 함수
const logResponse = (response: any) => {
  return response;
};

const fetcher = async (
  key: string,
  lang: string,
): Promise<FooterInfo[] | undefined> => {
  try {
    const result = await APIClient.Footer.getFooter({
      params: {
        lang,
      },
    });

    // API 응답 로깅
    logResponse(result.data);

    // API 응답 구조에 맞게 footers 배열 추출
    if (result.data.result?.footers) {
      return result.data.result.footers;
    }

    // 기본값 반환
    return [
      {
        section: 'Korea Headquarters',
        phone: '+82-2-6952-9370',
        email: 'info@fadu.io',
        address:
          '7F, 5, Teheran-ro 14-gil, Gangnam-gu, Seoul, Republic of Korea',
      },
      {
        section: 'Silicon Valley Office',
        phone: '669-230-4926',
        email: 'sales@fadu.io',
        address: '2712 Augustine Drive, Suite 220, Santa Clara, CA 95054',
      },
    ];
  } catch (error) {
    console.error('Error fetching footer data:', error);
    // 에러 발생 시 기본값 반환
    return [
      {
        section: 'Korea Headquarters',
        phone: '+82-2-6952-9370',
        email: 'info@fadu.io',
        address:
          '7F, 5, Teheran-ro 14-gil, Gangnam-gu, Seoul, Republic of Korea',
      },
      {
        section: 'Silicon Valley Office',
        phone: '669-230-4926',
        email: 'sales@fadu.io',
        address: '2712 Augustine Drive, Suite 220, Santa Clara, CA 95054',
      },
    ];
  }
};

export const useFooterData = (lang: string) => {
  const {
    data: footerData,
    error,
    isLoading,
  } = useSWR<FooterInfo[] | undefined>(['footer', lang], () =>
    fetcher('footer', lang),
  );

  return {
    footerData,
    isError: !!error,
    isLoading,
  };
};
