import { PopupItem } from '@/api/popup/types';
import { usePopupData } from '@/fetcher/usePopupData';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const { lang } = router.query;
  const { popupData } = usePopupData((lang as string) ?? 'en');
  const [popup, setPopup] = useState<PopupItem | undefined>(undefined);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    const fetchPopupData = async () => {
      try {
        // 현재 경로가 루트('/')가 아닌 경우 팝업을 표시하지 않음
        if (router.pathname !== '/') {
          return;
        }

        // localStorage에서 해당 팝업의 표시 여부 확인
        const dontShowPopupId = localStorage.getItem(`dontShowPopupId`);

        if (
          !dontShowPopupId ||
          dontShowPopupId !== popupData?.[0].id?.toString()
        ) {
          setPopup(popupData?.[0]);
          setIsOpen(true);
        }
      } catch (error) {
        console.error('Failed to fetch popup data:', error);
      }
    };

    fetchPopupData();
  }, [router.pathname, popupData]);

  const handleClose = () => {
    if (dontShowAgain && popup) {
      localStorage.setItem(`dontShowPopupId`, popup?.id?.toString() ?? '');
    }
    setIsOpen(false);
  };

  const handleDontShowAgainChange = () => {
    setDontShowAgain(!dontShowAgain);
  };

  if (!isOpen || !popup) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/50 p-5">
      <div className="w-full max-w-[800px] min-h-[250px] relative bg-white rounded-[20px] p-5">
        {/* Close button - always positioned at top right */}
        <button
          className="absolute right-5 top-5 w-[33px] h-[33px] cursor-pointer flex items-center justify-center"
          onClick={handleClose}
        >
          <img
            src="/images/icon/close-small.svg"
            alt="Close"
            className="w-4 h-4"
          />
        </button>

        <div className="flex flex-col items-center pt-12">
          {/* Title */}
          <div className="w-full max-w-[605px] text-center text-black md:text-xl text-[14px] font-bold font-['Inter'] md:leading-[1.4] leading-[19px]">
            {popup.title}
          </div>

          {/* Banner Image */}
          <a
            href={popup.externalLink}
            target="_blank"
            rel="noopener noreferrer"
            className="block md:w-full w-[301px] md:mt-12 mt-8"
          >
            <img
              className="w-full md:h-auto md:max-h-[83px] h-[35px] object-contain"
              src={popup.imageUrl}
              alt="Popup Banner"
            />
          </a>

          {/* Don't show again checkbox */}
          <div
            className="md:mt-12 mt-8 flex items-center gap-[15px] cursor-pointer"
            onClick={handleDontShowAgainChange}
          >
            <div
              className={`w-6 h-6 border border-gray-300 ${
                dontShowAgain ? 'bg-[#bc263d]' : 'bg-[#d9d9d9]'
              } flex items-center justify-center`}
            >
              {dontShowAgain && (
                <svg
                  className="w-4 h-4 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
                </svg>
              )}
            </div>
            <div className="text-center text-black text-[13px] font-normal font-['Open Sans'] leading-[27px]">
              Don't show this again
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
