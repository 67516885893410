import Logo from './Logo';
import LanguageSwitcher from './LanguageSwitcher';
import SearchBar from './SearchBar';
import { useEffect, useState, useRef } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import MobileNav from './MobileNav';
import LocaleLink from '../common/LocaleLink';

interface NavItem {
  id: number;
  label: string;
  link: string;
  subItems?: { id: number; label: string; link: string }[];
}

const Header = () => {
  const router = useRouter();
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [hasScrolledDown, setHasScrolledDown] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const fetchNavItems = async () => {
    const data = [
      {
        id: 1,
        label: 'Company',
        link: '/company',
        subItems: [
          { id: 9, label: 'About', link: '/company/about' },
          { id: 10, label: 'CEO Message', link: '/company/ceo-message' },
          { id: 11, label: 'Leadership', link: '/company/leadership' },
          { id: 12, label: 'Global Network', link: '/company/global-network' },
        ],
      },
      {
        id: 2,
        label: 'Products',
        link: '/product',
        subItems: [
          {
            id: 13,
            label: 'SSD Controller Solutions',
            link: '/products/ssd-controller-solutions',
          },
          {
            id: 14,
            label: 'SSD Solutions',
            link: '/products/ssd-solutions',
          },
          {
            id: 15,
            label: 'Power Solutions',
            link: '/products/power-solutions',
          },
          {
            id: 16,
            label: 'CXL Solutions',
            link: '/products/cxl-solutions',
          },
        ],
      },
      {
        id: 3,
        label: 'Market',
        link: '/market',
        subItems: [
          {
            id: 17,
            label: 'Customer Benefits',
            link: '/market/customer-benefits',
          },
          { id: 18, label: 'Business Model', link: '/market/business-model' },
        ],
      },
      {
        id: 4,
        label: 'Investors',
        link: 'https://ir.fadu.io/main/',
      },
      {
        id: 5,
        label: 'Press Room',
        link: '/press-room',
        subItems: [
          { id: 19, label: 'News', link: '/press-room/news' },
          { id: 20, label: 'Events', link: '/press-room/event' },
        ],
      },
      {
        id: 6,
        label: 'Blog',
        link: 'https://blogs.fadu.io/',
      },
      { id: 7, label: 'Careers', link: 'https://careers.fadu.io/' },
      { id: 8, label: 'Contact', link: '/contact' },
    ];
    setNavItems(data);
  };

  useEffect(() => {
    fetchNavItems();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isScrollingUp = currentScrollY < lastScrollY;

      if (currentScrollY > 60 && !hasScrolledDown) {
        setHasScrolledDown(true);
      }

      if (isFixedDisabled()) {
        if (currentScrollY > 0) {
          setIsHeaderFixed(isScrollingUp);
        } else {
          setIsHeaderFixed(false);
          setHasScrolledDown(false);
        }
      } else {
        if (currentScrollY > 0) {
          setIsHeaderFixed(isScrollingUp || !hasScrolledDown);
        } else {
          setIsHeaderFixed(false);
          setHasScrolledDown(false);
        }
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY, hasScrolledDown]);

  const handleSearch = () => {
    if (searchTerm.trim()) {
      // Get current lang query parameter
      const currentLang = router.query.lang;
      const searchPath = currentLang
        ? `/search?q=${encodeURIComponent(searchTerm)}&lang=${currentLang}`
        : `/search?q=${encodeURIComponent(searchTerm)}`;

      router.push(searchPath);
      setIsSearchOpen(false);
      setSearchTerm('');
    }
  };

  const isExternalLink = (url: string) => {
    return url.startsWith('http') || url.startsWith('https');
  };

  const getLineStyle = (link: string, isHovered: boolean) => {
    if (
      (router.pathname === link || router.pathname.startsWith(`${link}/`)) &&
      !isHovered
    ) {
      return 'opacity-100';
    }
    return 'opacity-0 group-hover:opacity-100';
  };

  const isCompanyPath = () => {
    return router.pathname.startsWith('/company');
  };

  const isFixedDisabled = () => {
    return (
      router.pathname.startsWith('/company') ||
      router.pathname.startsWith('/market') ||
      router.pathname.startsWith('/product') ||
      router.pathname.startsWith('/press-room')
    );
  };

  const handleNavigation = (link: string) => {
    // Scroll to top before navigation
    window.scrollTo(0, 0);

    if (isExternalLink(link)) {
      window.open(link, '_blank');
    } else {
      // Get current lang query parameter
      const currentLang = router.query.lang;
      const newPath = currentLang ? `${link}?lang=${currentLang}` : link;
      router.push(newPath);
    }
  };

  const handleSubItemNavigation = (itemLink: string, subItemLink: string) => {
    // Create a new URL object based on the current URL
    const url = new URL(window.location.origin + itemLink);

    // Preserve the lang parameter if it exists
    const currentLang = router.query.lang;
    if (currentLang) {
      url.searchParams.set('lang', currentLang as string);
    }

    // Remove any section query parameter
    url.searchParams.delete('section');

    // Add the hash
    url.hash = subItemLink.split('/').pop() || '';

    // Scroll to top before navigation
    window.scrollTo(0, 0);

    // Navigate to the new URL
    router.push(url.toString());
  };

  return (
    <header
      className={`w-full bg-black z-[60]
        ${isHeaderFixed ? 'fixed transition-none' : 'absolute transition-all duration-300'} top-0 left-0 right-0
        ${!isHeaderFixed && hasScrolledDown ? '-translate-y-full' : 'translate-y-0'}`}
    >
      <div className="w-full">
        <div className="container max-w-[1920px] mx-auto">
          <div className="h-[60px] px-4 md:px-8 lg:px-12 xl:px-[250px] flex items-center justify-between">
            <Logo />
            <div className="hidden lg:block">
              <nav className="flex items-center justify-center relative">
                {navItems.map((item) => (
                  <div
                    key={item.id}
                    className="relative group"
                    onMouseEnter={() => setHoveredItem(item.id)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    {isExternalLink(item.link) ? (
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavigation(item.link);
                        }}
                        className="px-2.5 py-4 whitespace-nowrap flex justify-center items-center gap-2.5 hover:text-white transition-colors relative"
                      >
                        <div className="text-white text-base font-bold lg:text-[17px] group-hover:font-bold font-['Inter'] leading-tight">
                          {item.label}
                        </div>
                        <div
                          className={`absolute bottom-[-3px] left-0 w-full h-0.5 bg-white ${getLineStyle(item.link, Boolean(hoveredItem && hoveredItem !== item.id))} transition-opacity duration-400`}
                        ></div>
                      </a>
                    ) : (
                      <LocaleLink
                        href={{ pathname: item.link }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavigation(item.link);
                        }}
                        className="px-2.5 py-4 whitespace-nowrap flex justify-center items-center gap-2.5 hover:text-white transition-colors relative"
                      >
                        <div className="text-white text-base font-bold lg:text-[17px] group-hover:font-bold font-['Inter'] leading-tight">
                          {item.label}
                        </div>
                        <div
                          className={`absolute bottom-[-3px] left-0 w-full h-0.5 bg-white ${getLineStyle(item.link, Boolean(hoveredItem && hoveredItem !== item.id))} transition-opacity duration-400`}
                        ></div>
                      </LocaleLink>
                    )}
                    {item.subItems && (
                      <div
                        className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 absolute left-[50%] -translate-x-[50%] w-screen bg-black"
                        style={{
                          width: '150vw',
                          top: 'calc(100% + 3px)',
                        }}
                      >
                        <div className="container max-w-[1920px] mx-auto">
                          <div className="flex justify-center items-center h-[60px]">
                            {item.subItems.map((subItem, index) => (
                              <LocaleLink
                                key={subItem.id}
                                href={{
                                  pathname: `${item.link}/#${subItem.link.split('/').pop()}`,
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleSubItemNavigation(
                                    item.link,
                                    subItem.link,
                                  );
                                }}
                                className={`px-5 justify-center items-center flex hover:text-gray-300
                                  ${index !== (item.subItems?.length ?? 0) - 1 ? 'border-r border-white' : ''}
                                `}
                              >
                                <div className="text-center text-white text-[15px] font-bold font-['Open Sans']">
                                  {subItem.label}
                                </div>
                              </LocaleLink>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </nav>
            </div>
            <div className="flex items-center gap-2.5 mr-0 xl:-mr-2.5">
              <div className="flex items-center gap-2.5">
                <LanguageSwitcher />
                <SearchBar
                  onSearchToggle={setIsSearchOpen}
                  isOpen={isSearchOpen}
                  inputRef={searchInputRef}
                />
                <button
                  className="lg:hidden"
                  onClick={() => setIsMobileMenuOpen(true)}
                >
                  <img src="/images/icon/menu.svg" alt="Menu" className="h-3" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`w-full bg-black transition-all duration-300 overflow-hidden ${
          isSearchOpen ? 'h-[60px]' : 'h-0'
        }`}
      >
        <div className="container max-w-[1920px] mx-auto px-4 h-full">
          <div className="flex items-center justify-center h-full">
            <div className="w-[800px] h-10 px-[15px] bg-white rounded-[60px] justify-start items-center inline-flex">
              <Image
                src="/images/icon/search.svg"
                alt="Search"
                width={20}
                height={20}
                className="mr-2 cursor-pointer invert"
                onClick={handleSearch}
              />
              <input
                ref={searchInputRef}
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full h-full bg-transparent focus:outline-none focus:ring-0 text-black border-none"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <MobileNav
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        navItems={navItems}
      />
    </header>
  );
};

export default Header;
