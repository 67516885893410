import Image from 'next/image';

interface SearchBarProps {
  onSearchToggle: (isOpen: boolean) => void;
  isOpen: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const SearchBar = ({ onSearchToggle, isOpen, inputRef }: SearchBarProps) => {
  const handleClick = () => {
    onSearchToggle(!isOpen);
    if (!isOpen && inputRef?.current) {
      // Auto-focus input when search bar opens
      // Using setTimeout to ensure DOM is updated before focusing
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  };

  return (
    <div className="w-6 h-6 relative flex items-center h-full">
      <Image
        src={
          isOpen ? '/images/icon/close-small.svg' : '/images/icon/search.svg'
        }
        alt={isOpen ? 'Close' : 'Search'}
        width={20}
        height={20}
        className="w-5 h-5 left-0.5 absolute cursor-pointer brightness-0 invert"
        onClick={handleClick}
      />
    </div>
  );
};

export default SearchBar;
