import Image from 'next/image';
import Link from 'next/link';
import LocaleLink from '../common/LocaleLink';

const Logo = () => {
  return (
    <div className="pt-1.5 pb-[5px] ml-0 xl:-ml-2.5 flex justify-start items-start">
      <LocaleLink
        href={{ pathname: '/' }}
        forceHome={true} // 홈페이지로 강제 이동
      >
        <Image
          src="/images/fadu-logo.svg"
          alt="Company Logo"
          width={140.21}
          height={24}
          className="shadow cursor-pointer"
        />
      </LocaleLink>
    </div>
  );
};

export default Logo;
