import useSWR from 'swr';
import { APIClient } from '../api/client';
import { PopupItem } from '@/api/popup/types';

const fetcher = async (
  key: string,
  lang: string,
): Promise<PopupItem[] | undefined> => {
  const result = await APIClient.Popup.getPopup({
    params: {
      lang,
    },
  });
  return result.data.result;
};

export const usePopupData = (lang: string) => {
  const {
    data: popupData,
    error,
    isLoading,
  } = useSWR<PopupItem[] | undefined>(['popup', lang], () =>
    fetcher('popup', lang),
  );

  return {
    popupData,
    isError: !!error,
    isLoading,
  };
};
