import Image from 'next/image';
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

const LanguageSwitcher = () => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const language = (router.query.lang as string) ?? 'en';

  const handleLanguageChange = (newLang: 'en' | 'ko') => {
    setIsOpen(false);

    // Get the current hash directly from the window location
    const currentHash =
      typeof window !== 'undefined' ? window.location.hash : '';

    // Create a new URL with the language parameter
    const currentPath = router.asPath.split('#')[0].split('?')[0];
    const queryParams = new URLSearchParams(
      router.query as Record<string, string>,
    );

    // Update or add the lang parameter
    queryParams.set('lang', newLang);

    // Remove the lang from the query object to avoid duplication
    if (queryParams.has('lang')) {
      const langParam = queryParams.get('lang');
      Object.keys(router.query).forEach((key) => {
        if (key !== 'lang') {
          queryParams.set(key, router.query[key] as string);
        }
      });
    }

    // Construct the new URL with query parameters and hash
    const newUrl = `${currentPath}?${queryParams.toString()}${currentHash}`;

    // Use window.location for a hard navigation to ensure the hash is preserved
    window.location.href = newUrl;
  };

  return (
    <div className="relative flex items-center h-full">
      <button className="w-6 h-6 relative" onClick={() => setIsOpen(!isOpen)}>
        <Image
          src="/images/language.svg"
          alt="Language Switch"
          width={24}
          height={24}
          className="w-6 h-6 left-0 top-0 absolute"
        />
      </button>

      {isOpen && (
        <div className="absolute top-full right-0 mt-2">
          <div className="h-24 justify-start items-start gap-2.5 inline-flex">
            <div className="w-[117px] px-5 py-2.5 bg-black flex-col justify-start items-start gap-2.5 inline-flex">
              <button
                className="py-[3px] justify-center items-center gap-2.5 inline-flex relative group"
                onClick={() => handleLanguageChange('en')}
              >
                <div
                  className={`text-white/90 text-[13px] font-['Open Sans'] leading-[27px] group-hover:font-bold ${language === 'en' ? 'font-bold' : ''}`}
                >
                  US / English
                </div>
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white transform scale-x-0 transition-transform group-hover:scale-x-100"></div>
              </button>
              <button
                className="py-[3px] justify-center items-center gap-2.5 inline-flex relative group"
                onClick={() => handleLanguageChange('ko')}
              >
                <div
                  className={`text-white/90 text-[13px] font-['Open Sans'] leading-[27px] group-hover:font-bold ${language === 'ko' ? 'font-bold' : ''}`}
                >
                  한국 / 한국어
                </div>
                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-white transform scale-x-0 transition-transform group-hover:scale-x-100"></div>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
