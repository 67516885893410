import { useState, useEffect } from 'react';
import LocaleLink from '../common/LocaleLink';
import { useRouter } from 'next/router';

interface NavItem {
  id: number;
  label: string;
  link: string;
  subItems?: { id: number; label: string; link: string }[];
}

interface MobileNavProps {
  isOpen: boolean;
  onClose: () => void;
  navItems: NavItem[];
}

const MobileNav = ({ isOpen, onClose, navItems }: MobileNavProps) => {
  const [openSubmenu, setOpenSubmenu] = useState<number | null>(null);
  const router = useRouter();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const isExternalLink = (url: string) => {
    return url.startsWith('http') || url.startsWith('https');
  };

  const handleNavigation = (link: string) => {
    // Scroll to top before navigation
    window.scrollTo(0, 0);

    if (isExternalLink(link)) {
      window.open(link, '_blank');
    } else {
      // Get current lang query parameter
      const currentLang = router.query.lang;
      const newPath = currentLang ? `${link}?lang=${currentLang}` : link;
      window.location.href = newPath;
    }
    onClose();
  };

  const handleSubItemNavigation = (itemLink: string, subItemLink: string) => {
    window.scrollTo(0, 0);
    const currentLang = router.query.lang;
    const hash = subItemLink.split('/').pop();
    const newPath = currentLang
      ? `${itemLink}?lang=${currentLang}#${hash}`
      : `${itemLink}#${hash}`;
    window.location.href = newPath;
    onClose();
  };

  return (
    <div
      className={`fixed inset-0 bg-black/80 transition-transform duration-300 z-50 ${
        isOpen ? 'translate-y-0' : '-translate-y-full'
      }`}
    >
      <div
        className={`w-full min-h-screen flex-col justify-start items-center gap-1 inline-flex overflow-y-auto transition-transform duration-300 bg-black
          ${isOpen ? 'translate-y-0' : '-translate-y-full'}`}
      >
        {/* 헤더 영역 */}
        <div className="w-full px-5 bg-black justify-between items-center inline-flex">
          <div className="h-[74px] py-5 justify-center items-center flex overflow-hidden">
            {/* 좌측 빈 공간 유지 */}
          </div>
          <button
            onClick={onClose}
            className="p-2.5 justify-start items-center gap-[25px] flex"
          >
            <img
              src="/images/icon/close.svg"
              alt="Close"
              className="w-3.5 h-3.5"
            />
          </button>
        </div>

        {/* 메뉴 영역 */}
        <div className="h-[333.60px] flex-col justify-start items-start inline-flex px-5 w-full">
          {navItems.map((item) => (
            <div key={item.id} className="w-full">
              <div
                className={`self-stretch h-[45px] flex justify-between items-center cursor-pointer`}
                onClick={() => {
                  if (item.subItems) {
                    setOpenSubmenu(openSubmenu === item.id ? null : item.id);
                  } else {
                    handleNavigation(item.link);
                  }
                }}
              >
                <div className="text-white text-base font-bold font-['Inter']">
                  {item.label}
                </div>
                {item.subItems && (
                  <div className="flex-col justify-start items-start gap-2.5 inline-flex mr-2.5">
                    <img
                      src="/images/icon/keyboard_arrow_down.svg"
                      alt="Expand"
                      className={`w-3 h-6 transition-transform ${
                        openSubmenu === item.id ? 'rotate-180' : ''
                      }`}
                    />
                  </div>
                )}
              </div>

              {/* 서브메뉴 */}
              {item.subItems && openSubmenu === item.id && (
                <div
                  className="pl-5 overflow-hidden transition-all duration-500 ease-in-out"
                  style={{
                    maxHeight: openSubmenu === item.id ? '500px' : '0',
                    opacity: openSubmenu === item.id ? 1 : 0,
                  }}
                >
                  {item.subItems.map((subItem) => (
                    <LocaleLink
                      key={subItem.id}
                      href={{
                        pathname: `${item.link}/#${subItem.link.split('/').pop()}`,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubItemNavigation(item.link, subItem.link);
                      }}
                      className="block text-gray-300 h-[45px] flex items-center hover:text-white transition-colors"
                    >
                      {subItem.label}
                    </LocaleLink>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
