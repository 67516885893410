import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, MouseEvent } from 'react';

interface LocaleLinkProps {
  href: { pathname: string; query?: Record<string, string> };
  children: ReactNode;
  className?: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  refresh?: boolean;
  forceHome?: boolean;
}

const LocaleLink = ({
  href,
  children,
  className,
  onClick,
  refresh = false,
  forceHome = false,
}: LocaleLinkProps) => {
  const router = useRouter();
  const { lang } = router.query;

  const pathnameWithHash = href.pathname || '';
  const [pathname, hash] = pathnameWithHash.split('#');

  const newHref = {
    pathname: pathname || href.pathname,
    query: lang ? { lang, ...href.query } : { ...href.query },
    ...(hash ? { hash: `#${hash}` } : {}),
  };

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(e);
    }

    if (forceHome && !e.defaultPrevented) {
      e.preventDefault();
      const query = lang ? { lang } : {};
      window.location.href =
        window.location.origin + (lang ? `?lang=${lang}` : '');
      return;
    }

    if (refresh && !e.defaultPrevented) {
      e.preventDefault();

      // 현재 URL에서 해시 부분을 제외한 기본 URL 가져오기
      const currentUrlWithoutHash = window.location.href.split('#')[0];

      if (href.pathname === '/' || href.pathname === router.pathname) {
        // 현재 페이지 새로고침 (해시 제외)
        window.location.href = currentUrlWithoutHash;
      } else {
        // 다른 페이지로 이동
        const query = lang ? { lang, ...href.query } : href.query;
        router.push({ pathname: href.pathname, query });
      }
    }

    // 해시가 있는 경우의 처리
    if (hash && !e.defaultPrevented) {
      e.preventDefault();
      window.scrollTo(0, 0); // 즉시 페이지 상단으로 이동

      // 같은 페이지에서 해시만 다른 경우에도 페이지를 새로 로드
      const query = lang ? { lang, ...href.query } : href.query;
      router.push({
        pathname,
        hash,
        query,
      });
    }
  };

  return (
    <Link href={newHref} className={className} onClick={handleClick}>
      {children}
    </Link>
  );
};

export default LocaleLink;
