import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useEffect, useState } from 'react';
import LocaleLink from '../common/LocaleLink';

const SiteMap = () => {
  const router = useRouter();
  const [breadcrumbs, setBreadcrumbs] = useState<
    { label: string; path: string }[]
  >([]);

  useEffect(() => {
    const generateBreadcrumbs = () => {
      const paths = [{ label: 'Home', path: '/' }];
      const pathname = router.pathname;
      const hash = router.asPath.split('#')[1];

      // Add main section
      if (pathname.includes('/company')) {
        paths.push({ label: 'Company', path: '/company' });
      } else if (pathname.includes('/product')) {
        paths.push({ label: 'Products', path: '/product' });
      } else if (pathname.includes('/market')) {
        paths.push({ label: 'Market', path: '/market' });
      } else if (pathname.includes('/press-room')) {
        paths.push({ label: 'Press Room', path: '/press-room' });
      }

      // Add subsection from hash
      if (hash) {
        const formattedHash = hash
          .split('-')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        paths.push({
          label: formattedHash,
          path: `${paths[paths.length - 1].path}/#${hash}`,
        });
      }

      setBreadcrumbs(paths);
    };

    generateBreadcrumbs();
  }, [router.pathname, router.asPath]);

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    hash: string,
    targetPathname: string,
  ) => {
    e.preventDefault();
    const { query } = router;
    const langQuery = query.lang ? `?lang=${query.lang}` : '';

    // 현재 페이지와 타겟 페이지가 같은지 확인
    const isSamePage = router.pathname === targetPathname;
    const fullPath = `${targetPathname}${langQuery}${hash}`;

    if (isSamePage) {
      // 같은 페이지인 경우에도 새로고침으로 처리
      window.location.href = fullPath;
    } else {
      // 다른 페이지로 이동시
      window.location.href = fullPath;
    }
  };

  return (
    <div className="min-h-[386px] py-[40px] sm:py-[60px] bg-[#242424] w-full">
      <div className="flex flex-col items-center gap-5 px-4">
        <div className="w-full max-w-[940px] h-auto min-h-[22px] justify-start items-center gap-[5px] inline-flex flex-wrap">
          {breadcrumbs.map((crumb, index) => (
            <React.Fragment key={crumb.path}>
              <LocaleLink
                href={{ pathname: crumb.path }}
                className="text-white text-[10px] font-normal font-['Open Sans'] leading-snug hover:text-gray-300"
              >
                {crumb.label}
              </LocaleLink>
              {index < breadcrumbs.length - 1 && (
                <div className="w-2.5 h-2.5 relative">
                  <img
                    src="/images/arrow_back.svg"
                    alt="arrow-forward"
                    className="w-2.5 h-2.5 rotate-180"
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="w-full max-w-[1200px] flex flex-col sm:flex-row justify-center items-start gap-5">
          <div className="flex flex-col justify-center items-start gap-5">
            <div className="w-[220px]">
              <span className="text-white text-[13px] font-bold font-['Open Sans'] leading-[27px]">
                Company
                <br />
              </span>
              <div className="text-white text-[13px] font-normal font-['Open Sans'] leading-[27px]">
                <LocaleLink
                  href={{ pathname: '/company/#about' }}
                  className="hover:text-gray-300"
                  onClick={(e) => handleClick(e, '#about', '/company')}
                >
                  About
                </LocaleLink>
                <br />
                <LocaleLink
                  href={{ pathname: '/company/#ceo-message' }}
                  className="hover:text-gray-300"
                  onClick={(e) => handleClick(e, '#ceo-message', '/company')}
                >
                  CEO Message
                </LocaleLink>
                <br />
                <LocaleLink
                  href={{ pathname: '/company/#leadership' }}
                  className="hover:text-gray-300"
                  onClick={(e) => handleClick(e, '#leadership', '/company')}
                >
                  Leadership
                </LocaleLink>
                <br />
                <LocaleLink
                  href={{ pathname: '/company/#global-network' }}
                  className="hover:text-gray-300"
                  onClick={(e) => handleClick(e, '#global-network', '/company')}
                >
                  Global Network
                </LocaleLink>
              </div>
            </div>
            <div className="w-[220px]">
              <span className="text-white text-[13px] font-bold font-['Open Sans'] leading-[27px]">
                Market
                <br />
              </span>
              <div className="text-white text-[13px] font-normal font-['Open Sans'] leading-[27px]">
                <LocaleLink
                  href={{ pathname: '/market/#customer-benefits' }}
                  className="hover:text-gray-300"
                  onClick={(e) =>
                    handleClick(e, '#customer-benefits', '/market')
                  }
                >
                  Customer Benefits
                </LocaleLink>
                <br />
                <LocaleLink
                  href={{ pathname: '/market/#business-model' }}
                  className="hover:text-gray-300"
                  onClick={(e) => handleClick(e, '#business-model', '/market')}
                >
                  Business Model
                </LocaleLink>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-start gap-5">
            <div className="w-[220px]">
              <span className="text-white text-[13px] font-bold font-['Open Sans'] leading-[27px]">
                Products
                <br />
              </span>
              <div className="text-white text-[13px] font-normal font-['Open Sans'] leading-[27px]">
                <LocaleLink
                  href={{ pathname: '/product/#ssd-controller-solutions' }}
                  className="hover:text-gray-300"
                  onClick={(e) =>
                    handleClick(e, '#ssd-controller-solutions', '/product')
                  }
                >
                  SSD Controller Solutions
                </LocaleLink>
                <br />
                <LocaleLink
                  href={{ pathname: '/product/#ssd-solutions' }}
                  className="hover:text-gray-300"
                  onClick={(e) => handleClick(e, '#ssd-solutions', '/product')}
                >
                  SSD Solutions
                </LocaleLink>
                <br />
                <LocaleLink
                  href={{ pathname: '/product/#power-solutions' }}
                  className="hover:text-gray-300"
                  onClick={(e) =>
                    handleClick(e, '#power-solutions', '/product')
                  }
                >
                  Power Solutions
                </LocaleLink>
                <br />
                <LocaleLink
                  href={{ pathname: '/product/#cxl-solutions' }}
                  className="hover:text-gray-300"
                  onClick={(e) => handleClick(e, '#cxl-solutions', '/product')}
                >
                  CXL Solutions
                </LocaleLink>
              </div>
            </div>
          </div>
          <div className="w-[220px]">
            <span className="text-white text-[13px] font-bold font-['Open Sans'] leading-[27px]">
              Press Room
              <br />
            </span>
            <div className="text-white text-[13px] font-normal font-['Open Sans'] leading-[27px]">
              <LocaleLink
                href={{ pathname: '/press-room/#news' }}
                className="hover:text-gray-300"
                onClick={(e) => handleClick(e, '#news', '/press-room')}
              >
                News
              </LocaleLink>
              <br />
              <LocaleLink
                href={{ pathname: '/press-room/#event' }}
                className="hover:text-gray-300"
                onClick={(e) => handleClick(e, '#event', '/press-room')}
              >
                Event
              </LocaleLink>
            </div>
          </div>
          <div className="w-[220px] text-white text-[13px] font-bold font-['Open Sans'] leading-[27px]">
            <a
              href="https://blogs.fadu.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300"
            >
              Blog
            </a>
            <br />
            <a
              href="https://ir.fadu.io/main/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300"
            >
              Investors
            </a>
            <br />
            <a
              href="https://careers.fadu.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300"
            >
              Careers
            </a>
            <br />
            <LocaleLink
              href={{ pathname: '/contact' }}
              className="hover:text-gray-300"
            >
              Contact
            </LocaleLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteMap;
